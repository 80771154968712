

const Button = ({ title, type = 'button', onClick = () => { }, className, disabled= false }) => {
    const defaultClass = 'py-2 px-8 w-32 bg-primary hover:bg-primary/90 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md cursor-pointer select-none'
    return (
        <button
            onClick={onClick}
            type={type}
            disabled={disabled}
            className={`${defaultClass} ${className}`}
        >
            {title}
        </button>
    )
}

export default Button;