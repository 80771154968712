import React, { useEffect, useState } from 'react';

const Modal = ({ isOpen, onClose, children , title = ''}) => {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setShowAnimation(true);
        } else {
            setTimeout(() => setShowAnimation(false), 300); // Match the duration of the animation
        }
    }, [isOpen]);

    if (!showAnimation && !isOpen) return null;

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ${
                isOpen ? 'opacity-100' : 'opacity-0'
            }`}
            onClick={onClose}
        >
            <div
                className={`bg-white rounded-md shadow-lg w-[90%] lg:w-1/3 sm:w-1/3 md:w-1/3 p-6 transform transition-transform duration-300 ${
                    isOpen ? 'scale-100' : 'scale-90'
                }`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex justify-between items-start mb-4">
                    <h2 className="text-xl text-primary font-semibold">{title}</h2>
                    <button onClick={onClose} className="text-xl border text-red-400 border-red-200 rounded-full py-1 px-3 hover:text-gray-700">
                        x
                    </button>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
