import moment from "moment";
import React from "react";

const AttendanceTable = ({ data }) => {
    return (
       <div className="w-full h-full  overflow-x-auto">
         <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr className="bg-gray-100 border-b border-gray-200">
                    <th scope="row" className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                        #
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                        Date
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                        Attendance
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                        Industry
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                        Trade
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                        ITI
                    </th>
                </tr>
            </thead>
            <tbody>
                {data?.data?.length > 0 ? (
                    data?.data?.map((item, index) => (
                        <tr
                            key={item?.id}
                            className="border-b hover:bg-gray-50 transition-colors"
                        >
                            <td className=" w-16 px-4 py-2 text-sm text-gray-800">
                                {index + 1}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-800">
                                {moment(item?.date).format('DD/MM/YYYY HH:mm A')}
                            </td>
                            <td className=" w-32 px-4 py-2 text-sm text-gray-800">
                                Marked
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-800">
                                {item?.name_of_industry_partner}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-800">
                                {item?.name_of_trade}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-800">
                                {item?.name_of_your_ITI}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan="3"
                            className="px-4 py-2 text-center text-sm text-gray-500"
                        >
                            No records found
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
       </div>
    );
};


export default AttendanceTable;
