

export const tradeList = [
    "Turner",
    "Electrician",
    "Welder",
    "Machinist",
    "Mechanic Electric Vehicle",
    "Warehouse Technician",
    "Electronic Mechanic",
    "Mechanic Motor Vehicle",
    "Textile Wet Processing Technician",
    "Physiotherapy Technician",
    "Health Sanitary Inspector",
    "Plumber",
    "Fitter",
].sort()


export const industryList = [
    "The Panipat Co - operative Sugar Mill, Panipat",
    "Surya Shakti Vessels Pvt.Ltd.",
    "Zelio Auto Pvt.Ltd.",
    "Rajasthan Spare House, Hisar",
    "Superhub Komponents Pvt.Ltd.",
    "Srishti Hyundai, Jind Bypass Rohtak",
    "Ch.Devilal Co - op Sugar Mill, Gohana",
    "ABC Impex(P) Ltd.",
    "Kapoor Industries Ltd.",
    "Municipal Corporation, Rohtak",
    "Civil Hospital, Rohtak",
    "Sunflag Global Hospital, Rohtak",
    "We Care Hospital, Rohtak",
    "Govt.Civil Hospital, Gurugram",
    "Enviro Integrated Facility Services Pvt.Ltd.",
    "Sankei Giken India Pvt.Ltd., Bawal"
].sort()

export const itiNameList = [
    "Bapoli",
    "Barana",
    "Fatehpur Biloch",
    "Hisar",
    "Kharkhoda",
    "Kiloi",
    "Mundlana",
    "Panipat",
    "Rohtak",
    "Sohna",
    "Tankri"
].sort()

export const haryanaCities = [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Gurugram",
    "Hisar",
    "Panipat",
    "Rohtak",
    "Karnal",
    "Yamunanagar",
    "Sonipat",
    "Panchkula"
  ];

export  const haryanaDistricts = [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurugram",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Nuh",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar"
  ];
  