import TraineeProfile from "src/layout/trainee/traineeProfile";


const TraineeLoginPage = () => {
    return (
        <>
            <TraineeProfile />
        </>
    )
}

export default TraineeLoginPage;