import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from "src/components/navbar";
import { useAuth } from 'src/context/AuthContext';
import { getHeaders, postMethodApiCall } from 'src/services/api';

const LoginProfile = () => {
    const navigate = useNavigate()
    const { setIsAuthenticated } = useAuth();
    // Initialize the form with useForm from react-hook-form
    const {
        register, handleSubmit, reset, formState: { errors }
    } = useForm();

    const loginAsITI = async (data) => {
        let res = await postMethodApiCall('/v1/api/iti/login', {...getHeaders(), loginType: 'iti'}, data)

        if (res?.status === 200 && res?.data?.success) {
            setIsAuthenticated(true)
            localStorage.setItem('token', res?.data?.token)
            localStorage.setItem('loginedUser', JSON.stringify(res?.data))
            await toast.success('Login Success !')
            reset()
            navigate('/iti-home')
        }
    }

    const onSubmit = (data) => {
        loginAsITI(data)
    };

    return (
        <div className="w-full h-[calc(100%-100px)]">
            <Navbar />
            <div className="relative h-full flex justify-center items-center py-3 sm:max-w-xs sm:mx-auto">
                <div className="h-[60%] min-w-[400px]   px-8 py-6 mt-4 text-left bg-white rounded-md shadow-sm">
                    <div className="flex flex-col justify-center items-center h-full">
                        <div className="flex flex-col items-center justify-center gap-2 mb-8">
                            <p className="m-0 text-[16px] font-semibold text-primary">
                                Login as ITI
                            </p>
                        </div>
                        
                        {/* Form element with handleSubmit */}
                        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-4">
                            {/* Username field */}
                            <div className="w-full flex flex-col gap-2">
                                <label className="font-[400] text-xs text-gray-600">Enter Username</label>
                                <input
                                    {...register("username", {
                                        required: "Username is required",
                                        minLength: { value: 3, message: "Username must be at least 3 characters long" }
                                    })}
                                    placeholder="Enter Username or Email"
                                    className={`border rounded-md px-3 py-2 mb-2 text-sm w-full outline-none ${
                                        errors.username ? "border-red-500" : ""
                                    }`}
                                />
                                {errors.username && <span className="text-red-500 text-xs">{errors.username.message}</span>}
                            </div>

                            {/* pin field */}
                            <div className="w-full flex flex-col gap-2">
                                <label className="font-[400] text-xs text-gray-600">Enter Pin</label>
                                <input
                                    {...register("pin", {
                                        required: "pin is required",
                                        minLength: { value: 6, message: "pin must be at least 6 characters long" }
                                    })}
                                    type="pin"
                                    placeholder="Enter Pin"
                                    className={`border rounded-md px-3 py-2 mb-2 text-sm w-full outline-none ${
                                        errors.pin ? "border-red-500" : ""
                                    }`}
                                />
                                {errors.pin && <span className="text-red-500 text-xs">{errors.pin.message}</span>}
                            </div>

                            {/* Submit button */}
                            <button
                                type="submit"
                                className="py-2 px-8 bg-primary hover:bg-primary/90 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md cursor-pointer select-none"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginProfile;
