

import React from 'react';

function CurrentLocation({ data, handleSave }) {

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="w-full flex flex-col gap-2">
        <label className="font-[400] text-xs text-gray-600">
          Latitude
        </label>
        <input
          value={data?.latitude || ''}
          placeholder="registration number"
          className="border rounded-md px-3 py-2 mb-5 text-sm w-full outline-none "
        />
      </div>
      <div className="w-full flex flex-col gap-2">
        <label className="font-[400] text-xs text-gray-600">Longitude</label>
        <input
          value={data?.longitude || ''}
          className="border placeholder:text-gray-300 rounded-md px-3 py-2 mb-5 text-sm w-full outline-none "
          type="number"
        />
      </div>
      <button
        onClick={handleSave}
        className="py-2 px-8  bg-primary hover:bg-primary/90 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md cursor-pointer select-none">
        Submit
      </button>
    </div>
  );
}

export default CurrentLocation;