

import Table from "./table";
import { getAuthHeaders, getMethodApiCall } from "src/services/api";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactPaginationStyle from "src/components/ReactPagination";

const OjtAttendanceLayout = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [data, setData] = useState({})
    const [startDate, setStartDate] = useState(moment().subtract(3, 'months').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'))
    const itiInfo = JSON.parse(localStorage.getItem('loginedUser'))

    const getTraineeAttendance = async () => {
        if (itiInfo) {
            let url = `/v1/api/iti/attendance-by-iti?name_of_your_ITI=${itiInfo?.iti?.iti_name}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
            let res = await getMethodApiCall(url, getAuthHeaders())
            if (res?.status === 200) {
                setData(res?.data)
            }
        }

    }

    useEffect(() => {
        getTraineeAttendance()
    // eslint-disable-next-line
    }, [page, limit, startDate, endDate])


    const handlePagination = async (page) => {
        setPage(page?.selected + 1);
    };

    return (
        <div className="container px-2 mx-auto my-5 min-h-96 ">
            <div className="mb-2 flex flex-col items-center justify-between md:flex-row gap-2">
                <p className="m-0 text-[16px] font-semibold text-primary">
                    ITI / OJT Attendance
                </p>
                <div className="flex flex-col items-center justify-center md:flex-row">
                    <a
                        rel="noreferrer"
                        target='_blank'
                        href='https://drive.google.com/drive/u/0/home'
                        className='border border-primary gap-1 text-white bg-primary hover:bg-primary/90 focus:ring-4 focus:outline-none focus:ring-primary/50 font-medium rounded-md hover:shadow-sm text-sm px-4 py-1.5 text-center inline-flex items-center  me-2 mb-2 p-1'
                    >
                        Update Academic Calendar
                    </a>

                    <a
                        rel="noreferrer"
                        target='_blank'
                        href='https://drive.google.com/drive/u/0/home'
                        className='border border-primary gap-1 text-white bg-primary hover:bg-primary/90 focus:ring-4 focus:outline-none focus:ring-primary/50 font-medium rounded-md hover:shadow-sm text-sm px-4 py-1.5 text-center inline-flex items-center  me-2 mb-2 p-1'
                    >
                        DST MS
                    </a>

                </div>
            </div>
            <div className="rounded-md bg-white shadow p-2 ">
                <div
                    className="mb-2 grid grid-cols-2 gap-2 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2">

                    <input
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="border placeholder:text-gray-300 rounded-md px-3 py-2 text-sm w-full outline-none "
                        type="date"
                    />
                    <input
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="border placeholder:text-gray-300 rounded-md px-3 py-2 text-sm w-full outline-none "
                        type="date"
                    />


                    <select
                        onChange={(e) => setLimit(e.target.value)}
                        className="border rounded-md px-3 py-2 text-sm w-full outline-none "
                    >
                        <option>Rows</option>
                        <option value={10}>10 Rows</option>
                        <option value={25}>25 Rows</option>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                    </select>

                    <div className="flex justify-center items-center space-x-2">
                        <ReactPaginationStyle
                            total={data?.meta?.totalRecords}
                            currentPage={page}
                            handlePagination={handlePagination}
                            limit={limit}
                        />

                    </div>


                </div>
                <Table data={data} />
            </div>
        </div>
    )
}

export default OjtAttendanceLayout;