import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import { postMethodApiCall, getHeaders } from "src/services/api";

const LoginTraineeForm = ({ setIsLogin }) => {
    const { setIsAuthenticated } = useAuth();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const navigate = useNavigate()

    const loginAsTrainee = async (data) => {
        let res = await postMethodApiCall('/v1/api/trainee/login', {...getHeaders(), loginType:'trainee'}, data)
        if (res?.status === 200 && res?.data?.success) {
            await toast.success('Login Success !')
            setIsAuthenticated(true)
            localStorage.setItem('token', res?.data?.token)
            localStorage.setItem('loginedUser', JSON.stringify(res?.data))
            navigate('/Trainee-details')
            setIsLogin(false)
            reset()
        }
    }

    const onSubmit = (data) => {
        loginAsTrainee(data)
    };

    return (
        <>
            {/* <ToastContainer /> */}
            <div className="relative h-full flex justify-center items-center py-3 sm:max-w-xs sm:mx-auto">
                <div className="h-[60%] min-w-[400px]  px-8 py-6 mt-4 text-left bg-white rounded-md shadow-sm">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col justify-center items-center h-full">
                        <div className="flex flex-col items-center justify-center gap-2 mb-8">
                            <p className="m-0 text-[16px] font-semibold text-primary">
                                DST Trainee Attendance
                            </p>
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">
                                Enter your registration number
                            </label>
                            <input
                                {...register("registration_id", { required: "Registration Id is required" })}
                                placeholder="Registration Id"
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none "
                            />
                            {errors.registration_id && (
                                <span className="text-red-500 text-xs">{errors.registration_id.message}</span>
                            )}
                        </div>
                        <div className="my-2 w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Enter your date of birth</label>
                            <input
                                {...register("date_of_birth", { required: "Date of birth is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none "
                                type="date"
                            />
                            {errors.date_of_birth && <span className="text-red-500 text-xs">{errors.date_of_birth.message}</span>}
                        </div>
                        <button
                            type="submit"
                            className="py-2 mt-2 px-8  bg-primary hover:bg-primary/90 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md cursor-pointer select-none">
                            Submit
                        </button>

                    </form>
                </div>
            </div>

        </>
    )
}

export default LoginTraineeForm;

