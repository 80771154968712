import LoginLayout from "src/layout/iti/login";


const ITILoginPage = () => {
    return (
        <>
            <LoginLayout />
        </>
    )
}

export default ITILoginPage;