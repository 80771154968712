import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const isAuth = () => {
    const user = { loggedIn: localStorage.getItem("token") !== null ? true : false }
    return user && user.loggedIn
}

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(isAuth());

    const login = () => {
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, logout, }}>
            {children}
        </AuthContext.Provider>
    );
};
