


const ButtonIcon = ({ icon, title, className, onClick = ()=>{} }) => {
    const defaultClasses = 'border border-primary gap-1 text-primary bg-primary/10 hover:bg-primary/10 focus:ring-4 focus:outline-none focus:ring-primary/50 font-medium rounded-md hover:shadow-sm text-sm px-5 py-2 text-center inline-flex items-center  me-2 mb-2'
    return (
        <button
            onClick={onClick}
            type="button" className={` ${defaultClasses} ${className}`}>
            {icon && icon}
            {title}
        </button>
    )
}
export default ButtonIcon