

import Navbar from "src/components/navbar";
import LoginTraineeForm from "./loginform";
import { useState } from "react";
import TraineeDetails from "./details";

const TraineeProfile = () => {
    const [isLogin, setIsLogin] = useState(true) 

    return (
        <div className="w-full h-[calc(100%-100px)] ">
            <Navbar />
            {isLogin ? <LoginTraineeForm setIsLogin={setIsLogin} /> : <TraineeDetails setIsLogin={setIsLogin} />}
        </div>

    )
}

export default TraineeProfile;

