import AttendanceHistoryLayout from "src/layout/trainee/attendance";


const AttendanceHistory = () => {
    return (
        <>
            <AttendanceHistoryLayout />
        </>
    )
}

export default AttendanceHistory;