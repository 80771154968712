
import { useNavigate } from 'react-router-dom';
import ButtonIcon from './custom/ButtonIcon';

const powericon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
</svg>


const Appbar = () => {
    const navigate = useNavigate()

    const handleLogout = () => {
        navigate('/')
        localStorage.clear()
    }

    return (
        <div className=' bg-primary w-full'>
            <div className="container mx-auto flex justify-end items-center">
                <ButtonIcon
                    onClick={handleLogout}
                    icon={powericon}
                    title='Logout'
                    className='text-white mt-1 px-0 pr-0'
                />
            </div>
        </div>

    )
}

export default Appbar;