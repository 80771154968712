import OjtAttendanceLayout from "src/layout/iti/ojtAttendance";


const ItiHome = () => {
    return (
        <>
            <OjtAttendanceLayout />
        </>
    )
}

export default ItiHome;