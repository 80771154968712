import LoginLayout from "src/layout/login";


const LoginPage = () => {
    return (
        <>
            <LoginLayout />
        </>
    )
}

export default LoginPage;