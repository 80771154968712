import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import Navbar from "src/components/navbar";
import Appbar from "src/components/appbar";

const ProtectedRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? <div className="w-full h-full overflow-x-hidden">
        <Appbar />
        <Navbar />
        {element}
    </div> : <Navigate to="/login" />;
};

export default ProtectedRoute;
