
import {
    createBrowserRouter,
} from "react-router-dom";
import LoginPage from "src/pages/login";
import ITILogin from "src/pages/iTILogin";
import TraineeLogin from "src/pages/traineeLogin";
import AttendanceHistory from "src/pages/attendanceHistory";
import ItiHome from "src/pages/itiHome";
import ProtectedRoute from "./ProtectedRoute";
import TraineeDetails from 'src/pages/traineeDetails'

const router = createBrowserRouter([
    {
        path: "/",
        element: <LoginPage />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/ITI-login",
        element: <ITILogin />
    },
    {
        path: "/Trainee-login",
        element: <TraineeLogin />
    },
    {
        path: "/Trainee-details",
        element: <ProtectedRoute element={<TraineeDetails />} />
    },
    {
        path: "/attendance-history",
        element: <ProtectedRoute element={<AttendanceHistory />} /> // Protect this route
    },
    {
        path: "/iti-home",
        element: <ProtectedRoute element={<ItiHome />} /> // Protect this route
    }
]);


export default router