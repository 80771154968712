import axios from "axios"
import { toast } from "react-toastify";

axios.interceptors.response.use(response => {
    // console.log(response)
    return response

}, error => {
    // console.log(error)
    if (error?.code === "ERR_NETWORK") {
        toast.error(error?.name, error?.message)
    }
    return Promise.reject(error);
}
);

export const getAuthHeaders = () => {
    return {
        'Content-Type': 'application/json',
        loginType: JSON.parse(localStorage.getItem('loginedUser')).iti ? 'iti' : 'trainee',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
}


export const getAuthHeadersFormdata = () => {
    return {
        "Content-Type": "multipart/form-data",
        loginType: JSON.parse(localStorage.getItem('loginedUser')).iti ? 'iti' : 'trainee',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
}

export const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        // loginType: JSON.parse(localStorage.getItem('loginedUser')).iti ? 'iti' : 'trainee',
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
}


export const postMethodApiCall = async (apiPath, header, bodyData = {}) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiPath}`, bodyData, { headers: { ...header } })
        if (res?.status === 200 || res?.status === 201) {
            return res
        } else {
            toast.error(res?.data?.Message || 'Something went wrong. Please try later.')
            return
        }
    } catch (error) {
        let errMsg = error.response?.data?.error
        toast.error(errMsg || 'Something went wrong. Please try later.')
        return error
    }
}

export const putMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_BASE_URL}${apiPath}`, bodyData, { headers: { ...header } })
        return res
    } catch (error) {
        // let errMsg = error.response?.data?.error
        return { status: error.response?.status }
    }
}

export const getMethodApiCall = async (apiPath, header, bodyData = {}) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiPath}`, { headers: { ...header } })
        return res
    } catch (error) {
        if (error.response?.status === 401) {
            localStorage.clear()
            window.location.href = '/'
        }
        return { status: error.response?.status }
    }
}

export const patchMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.patch(`${process.env.REACT_APP_BASE_URL}${apiPath}`, bodyData, { headers: { ...header } })
        return res
    } catch (error) {
        // let errMsg = error.response?.data?.error
        return { status: error.response?.status }
    }
}

export const deleteMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}${apiPath}`, { headers: header, data: bodyData })
        return res
    } catch (error) {
        // let errMsg = error.response?.data?.error
        return { status: error.response?.status }
    }
}