
import TraineeDetailsLayout from 'src/layout/trainee/traineeProfile/details'

const TraineeDetails = () => {
    const loginedUser = JSON.parse(localStorage.getItem('loginedUser'))

    return (
        <>
            <TraineeDetailsLayout data={loginedUser?.trainee} />
        </>
    )
}

export default TraineeDetails;