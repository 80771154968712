
import itiLogo from 'src/assets/icons/iti-logo.png'
import haryanaGovIcon from 'src/assets/icons/Emblem_of_Haryana.svg.png'
import { Link } from 'react-router-dom';

const Navbar = () => {

    return (
        <div className='w-full bg-white shadow px-2'>
            <div className="container mx-auto w-full flex justify-between items-center py-2 ">
                <Link to='/'>
                    <img alt="iti-logo" src={itiLogo} className=' w-28 object-contain' />
                </Link>
                <h4 className='font-semibold text-center text-lg text-primary '>Dual System of Training (DST)</h4>
                <img alt="iti-logo" src={haryanaGovIcon} className='w-10 object-contain' />
            </div>
        </div>
    )
}

export default Navbar;